import { Injectable } from '@angular/core';
import {HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {Platform, ToastController} from '@ionic/angular';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'any'
})

export class ResponseInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private platform: Platform,
    private toast: ToastController) {}
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req)
      .pipe(
        tap(
          (response) => {
            if (response instanceof HttpResponse) {
              if (response.url.includes('send')) {
                this.showSuccessToast(response.body.success).then();
              }
            }
          },
          (error) => {
            if (error instanceof HttpErrorResponse) {
              this.showErrorToast(error.message).then();
            }
          }
        )
      );
  }

  async showErrorToast(error: string) {
    const toast = await this.toast.create({
      mode: 'ios',
      message: error,
      position: 'top',
      color: 'danger',
      buttons: [
        {
          side: 'end',
          icon: this.platform.is('desktop') ? '' : 'close',
          text: this.platform.is('desktop') ? 'schließen' : '',
          handler: () => {
            this.router.navigateByUrl('/');
          }
        }
      ]
    });
    toast.present().then();
  }

  async showSuccessToast(message: string) {
    const toast = await this.toast.create({
      mode: 'ios',
      message,
      position: 'top',
      color: 'success',
      buttons: [
        {
          side: 'end',
          icon: this.platform.is('desktop') ? '' : 'close',
          text: this.platform.is('desktop') ? 'schließen' : '',
          handler: () => {
            this.router.navigateByUrl('/');
          }
        }
      ]
    });
    toast.present().then();
  }
}

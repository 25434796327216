import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Section} from '../../interfaces/section.interface/section';
import {environment} from '../../../environments/environment';
import {Survey} from '../../interfaces/survey.interface/survey';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  constructor(private http: HttpClient) { }

  sections() {
    return this.http.get<Array<Section>>('assets/data/questions.json');
  }

  send(data: Survey) {
    return this.http.post(environment.api + 'send', data);
  }
}

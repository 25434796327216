import { HTTP_INTERCEPTORS } from '@angular/common/http';
import {ResponseInterceptor} from '../interceptors/response.interceptor/response-interceptor';

export const httpInterceptorProvider = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: ResponseInterceptor,
    multi: true
  }
];
